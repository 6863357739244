// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB7M_3fLYVRsLc_njX9dmaThxoY14AmYWo',
  authDomain: 'react-portfolio-3e496.firebaseapp.com',
  databaseURL: 'https://react-portfolio-3e496.firebaseio.com',
  projectId: 'react-portfolio-3e496',
  storageBucket: 'react-portfolio-3e496.appspot.com',
  messagingSenderId: '41504246984',
  appId: '1:41504246984:web:a3cb584abb712f25b0a9fd',
  measurementId: 'G-7DTYYFJTJ9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore();
export default db;
